import { useAtomValue, useSetAtom } from "jotai";
import {
  allTableRecordsAtom,
  autoFillMobileAtom,
  registeredUserAtom,
  totalRecordsAtom,
  userFilesAtom,
  userStatusAtom,
} from "atoms";
import { TokenUtils } from "utils";

export const useLogout = () => {
  const setUserStatus = useSetAtom(userStatusAtom);
  const setAutoFillMobile = useSetAtom(autoFillMobileAtom);
  const setRegisteredUserInfo = useSetAtom(registeredUserAtom);
  const setUserFiles = useSetAtom(userFilesAtom);
  const  setAllTableRecords = useAtomValue(allTableRecordsAtom);
  const setTotalRecords = useAtomValue(totalRecordsAtom);
  const logOut = () => {
    TokenUtils.remove();
    localStorage.removeItem("userInfo");
    setUserStatus(null);
    setAutoFillMobile(false);
    setRegisteredUserInfo({});
    setUserFiles({});
    setAllTableRecords([]);
    setTotalRecords(0);
  };

  return logOut;
};
