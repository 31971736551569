import { userInfoAtom, userStatusAtom } from "atoms";
import Footer from "components/shared/footer";
import { languagesEnum, userStatusEnum } from "constants";
import { useEffectOnce, useRedirectRoutes, useTranslationHook } from "hooks";
import { useAtom, useAtomValue } from "jotai";
import React, { Suspense, useEffect, useState } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import {
  Loader,
  PageWrapper,
} from "shared-styled-components/layout-shared-components";
import "styles/global.css";
import routes from "./routes";

export default function App() {
  const [user, setUser] = useAtom(userInfoAtom);
  const userStatus = useAtomValue(userStatusAtom);

  const { handleLanguageChange } = useTranslationHook();

  const RouteWrapper = ({
    component: Component,
    path,
    isSubRoute,
    ...rest
  }) => {
    const route = useRedirectRoutes(path, isSubRoute);
    const [loading, setLoading] = useState(true);
    const [currentRoute, setCurrentRoute] = useState("");
    const includedFooterPages = [
      "/register",
      "/",
      "/verify",
      "/setup",
      "/forget-password",
    ];

    useEffect(() => {
      setCurrentRoute(route);
      setLoading(false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [route]);

    if (loading) return includedFooterPages.includes(path) && <Loader />;

    if (currentRoute) return <Navigate to={route} />;
    return (
      <PageWrapper $isAuthPage={includedFooterPages?.includes(path)}>
        <Component {...rest} />
        {includedFooterPages?.includes(path) && <Footer />}
      </PageWrapper>
    );
  };

  useEffectOnce(() => {
    // get user info when refresh the page
    if (userStatus === userStatusEnum.active) setUser();
  });

  useEffect(() => {
    // set language from atom if not saved in local storage
    const savedLanguage = localStorage.getItem("lang");
    if (!savedLanguage && user.preferredLanguage)
      handleLanguageChange(languagesEnum[user.preferredLanguage]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <div>
      <BrowserRouter>
        <Suspense fallback={<Loader />}>
          <Routes>
            {routes.map(
              ({ label, titleKey, path, children, component: Component }) => (
                <Route
                  key={label}
                  path={path}
                  element={
                    <RouteWrapper
                      component={Component}
                      titleKey={titleKey}
                      path={path}
                    />
                  }
                >
                  {children &&
                    children.map(
                      ({ label, path, component: ChildComponent }) => (
                        <Route
                          key={label}
                          path={path}
                          element={
                            <RouteWrapper
                              component={ChildComponent}
                              titleKey={titleKey}
                              path={path}
                              isSubRoute={true}
                            />
                          }
                        />
                      )
                    )}
                </Route>
              )
            )}
          </Routes>
        </Suspense>
      </BrowserRouter>
    </div>
  );
}
