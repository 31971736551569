import addressBookIcon from "assets/address-book.svg";
import bell from "assets/bell.svg";
import chat from "assets/chat.svg";
import myContactsIcon from "assets/contacts.svg";
import dashboardUnselected from "assets/dashboard-icon-unselected.svg";
import dropDowHelpCenter from "assets/dropdown-help-center.svg";
import dropDownLocation from "assets/dropdown-location.svg";
import dropDownProfile from "assets/dropdown-profile.svg";
import logout from "assets/logout-icon.svg";
import orderManagementUnselected from "assets/orders-management-unselected.svg";
import settings from "assets/settings-icon.svg";

export const MOBILE_VIEW_NAV_LINKS = (t) => [
  {
    icon: chat,
    id: 0,
    label: t("chat"),
    link: "",
  },
  {
    icon: bell,
    id: 1,
    label: t("notifications"),
    link: "",
  },
  {
    icon: dashboardUnselected,
    id: 2,
    label: t("dashboard"),
    link: "/",
  },
  {
    icon: orderManagementUnselected,
    id: 3,
    label: t("ordersManagement"),
    link: "./order-management",
  },
  {
    icon: dropDownLocation,
    id: 4,
    label: t("myLocations"),
    link: "./my-locations",
  },
  {
    icon: myContactsIcon,
    id: 5,
    label: t("myContacts"),
    link: "./my-contacts",
  },
  {
    icon: addressBookIcon,
    id: 6,
    label: t("addressBook"),
    link: "./address-book",
  },
  {
    icon: dropDowHelpCenter,
    id: 7,
    label: t("support"),
    link: "./support",
  },
  {
    icon: dropDowHelpCenter,
    id: 8,
    label: t("helpCenter"),
    link: "",
  },
  {
    icon: settings,
    id: 9,
    label: t("settings"),
    link: "./settings",
  },
];

export const DROP_DOWN_MENU_NAV_LINKS = [
  {
    icon: dropDownProfile,
    id: 0,
    label: "myProfile",
    link: "./my-profile",
  },
  {
    icon: settings,
    id: 1,
    label: "settings",
    link: "./settings",
  },
  {
    icon: dropDowHelpCenter,
    id: 2,
    label: "helpCenter",
    link: "",
  },
  {
    icon: logout,
    id: 3,
    label: "logout",
    link: "/",
  },
];
